<template>
  <KepemilikanSahamForm mode="Tambah" module="Data Kepemilikan Saham"> </KepemilikanSahamForm>
</template>

<script>
import KepemilikanSahamForm from './form';

const KepemilikanSahamAdd = {
  name: 'KepemilikanSahamAdd',
  components: { KepemilikanSahamForm },
};

export default KepemilikanSahamAdd;
</script>
